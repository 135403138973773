import { createBrowserRouter, Navigate } from 'react-router-dom'
import SearchByNpi from 'pages/SearchByNpi'
import ErrorPage from 'pages/ErrorPage/ErrorPage'
import { LoggedInLayout } from 'layouts/LoggedInLayout'
import { DEFAULT_ROUTE } from 'variables'

export const router = createBrowserRouter([
	{
		// Callback for OAuth will redirect to /search-by-npi
		path: '/callback',
		element: <Navigate to={DEFAULT_ROUTE} replace />
	},
	{
		path: '/',
		element: <LoggedInLayout />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: '',
				element: (
					<SearchByNpi />
				)
			},
			{
				path: 'search-by-npi',
				element: (
					<SearchByNpi />
				)
			}
		]
	}
])
