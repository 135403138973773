import {
	Heading,
	Link,
	Text
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { KeyPropertyContainer, KeyValues } from 'components/KeyPropertyContainer'
import STATES from './States'
import './ProviderInfo.css'

const GENDERS = {
	M: 'Male',
	F: 'Female'
}

function createExternalLink(location: string) {
	if (!location) {
		return null
	}
	let url
	for (const loc of [location, `https://${location}`]) {
		try {
			console.log(loc)
			url = new URL(loc)
			break
		} catch (ex) {
			// gobble
		}
	}
	if (!url) {
		return null
	}
	const hostname = url.hostname
	return (
		<Link href={url.href} isExternal>
			{hostname} <ExternalLinkIcon mx="2px" />
		</Link >
	)
}

const ProviderNameSummary = ({ first_name, last_name }: { first_name: string, last_name: string }) => (
	<Heading as="h2" variant="with-primary">
		{first_name} {last_name}
	</Heading>
)

type SpecialtySummaryProps = {
	specialty_name: string,
	city: string,
	state: string
}

const SpecialtyLocationSummary = ({ specialty_name, city, state }: SpecialtySummaryProps) => {
	if (specialty_name) {
		if (city && state) {
			return (
				<Heading as="h5">
					{specialty_name} in {city}, {state}
				</Heading>
			)
		} else {
			return (
				<Text>{specialty_name}</Text>
			)
		}
	} else if (city && state) {
		return (
			<Text>{city}, {state}</Text>
		)
	}
	return null
}

type AddressProps = {
	address1: string,
	address2?: string,
	city: string,
	state: string,
	zip5: string,
	zip4?: string
}

const Address = (props: AddressProps) => {
	const {
		address1,
		address2,
		city,
		state,
		zip5,
		zip4
	} = props
	const zip = `${zip5}${zip4 ? `-${zip4}` : ''}`
	const parts = [ address1 ]
	address2 && parts.push(address2)
	city && state && zip && parts.push(`${city}, ${state} ${zip}`)

	return (
		<Link
			href={`https://www.google.com/maps/place/${parts.join(',').replace(' ', '+')},+USA`}
			isExternal
		>
			{parts.join(', ')} <ExternalLinkIcon mx="2px" />
		</Link>
	)
}

type LicenseProps = {
	title: string,
	name?: string,
	id?: string,
	expired?: boolean,
	state?: string
	type?: string,
	issue_date?: string,
	expiration_date?: string
}

const License = (props: LicenseProps) => {
	const licenseKP: KeyValues = {}
	props.id && (licenseKP.ID = props.id)
	props.name && (licenseKP.Name = props.name)
	props.state && (licenseKP.State = props.state)
	props.type && (licenseKP.Type = props.type)
	props.issue_date && (licenseKP['Issue Date'] = props.issue_date)
	props.expiration_date && (licenseKP['Expiration Date'] = props.expiration_date)
	props.expired !== undefined && (licenseKP.Expired = `${!!props.expired}`)
	return (
		<KeyPropertyContainer
			title={props.title}
			keyValues={licenseKP}
		/>
	)
}

const DeaLicense = ({ license }) => (
	<License
		title={STATES[license.state]}
		name={license.id}
		state={license.state}
		expired={license.expired}
		expiration_date={license.expiration_date}
	/>
)

const AbmsLicense = ({ license }) => (
	<License
		title={license.board_name}
		// NB: AMBS does not have `expired` field - we should add it
		issue_date={license.effective_date}
		expiration_date={license.expiration_date}
	/>
)

const StateLicense = ({ license }) => (
	<License
		title={STATES[license.state]}
		id={license.id}
		type={license.license_type}
		expired={license.expired}
		issue_date={license.issue_date}
		expiration_date={license.expiration_date}
	/>
)

type LicensesProps = {
	licenses: []
}

const DeaLicenses = (props: LicensesProps) => {
	if (!props.licenses.length) {
		return null
	}
	const licenses = props.licenses.map((lic, index) => (
		<DeaLicense key={`item-${index}`} license={lic} />
	))
	return (
		<>
			<Heading as="h5" paddingTop="32px" paddingBottom="32px">
				DEA Licenses
			</Heading>
			{licenses}
		</>
	)
}

const AbmsLicenses = (props: LicensesProps) => {
	if (!props.licenses.length) {
		return null
	}
	const licenses = props.licenses.map((lic, index) => (
		<AbmsLicense key={`item-${index}`} license={lic} />
	))
	return (
		<>
			<Heading as="h5" paddingTop="32px" paddingBottom="32px">
				ABMS Licenses
			</Heading>
			{licenses}
		</>
	)
}

const StateLicenses = (props: LicensesProps) => {
	if (!props.licenses.length) {
		return null
	}
	const licenses = props.licenses.map((lic, index) => (
		<StateLicense key={`item-${index}`} license={lic} />
	))
	return (
		<>
			<Heading as="h5" paddingTop="32px" paddingBottom="32px">
				State Licenses
			</Heading>
			{licenses}
		</>
	)
}

const SpecialtyLink = ({ display_name, taxonomy_code }: { display_name: string, taxonomy_code: string }) => (
	<Link href={`https://taxonomy.nucc.org/?searchTerm=${taxonomy_code}`} isExternal>
		{display_name} <ExternalLinkIcon mx="2px" />
	</Link>
)

const Group = ({ group }) => {
	const groupKP: KeyValues = {}
	groupKP['Group Name'] = group.name
	if (group.tin) {
		groupKP.TIN = group.tin
	}
	if (group.website) {
		groupKP.Website = createExternalLink(group.website)
	}
	return (
		<KeyPropertyContainer
			title={group.name}
			keyValues={groupKP}
		/>
	)
}

const Groups = ({ groups }) => {
	if (!groups.length) {
		return null
	}
	const grps: JSX.Element[] = groups.map((group, index) => (
		<Group key={`item-${index}`} group={group} />
	))
	return (
		<>
			<Heading as="h5" paddingTop="32px" paddingBottom="32px">
				Groups
			</Heading>
			{grps}
		</>
	)
}

const Location = ({ location }) => {
	const locationKP: KeyValues = {}
	locationKP.Address = (
		<Address
			address1={location.address1}
			address2={location.address2}
			city={location.city}
			state={location.state}
			zip5={location.zip5}
			zip4={location.zip4}
		/>
	)
	location.phone && (locationKP["Phone"] = location.phone)
	location.fax && (locationKP["Fax"] = location.fax)
	if (location.website) {
		locationKP.Website = createExternalLink(location.website)
	}
	location.office_hours && (locationKP["Office Hours"] = location.office_hours)

	return (
		<KeyPropertyContainer
			title={location.location_name}
			keyValues={locationKP}
		/>
	)
}

const Locations = ({ locations }) => {
	if (!locations.length) {
		return null
	}
	const locs: JSX.Element[] = locations.map((location, index) => (
		<Location key={`item-${index}`} location={location} />
	))
	return (
		<>
			<Heading as="h5" paddingTop="32px" paddingBottom="32px">
				Locations
			</Heading>
			{locs}
		</>
	)
}

export type ProviderInfoProps = {
	provider: any
}

type SpecialtyProps = {
	classification: string,
	display_name: string,
	taxonomy_code: string,
	grouping: string,
	specialization: string,
	description: string
}

const Specialty = (specialty: SpecialtyProps) => {
	const specialtyKP: KeyValues = {}
	specialtyKP.Name = (
		< SpecialtyLink
			key={specialty.taxonomy_code}
			display_name={specialty.display_name}
			taxonomy_code={specialty.taxonomy_code}
		/>
	)
	specialtyKP['Taxonomy Code'] = specialty.taxonomy_code
	specialtyKP.Classification = specialty.classification
	specialty.specialization && (specialtyKP.Specialization = specialty.specialization)
	specialtyKP.Group = specialty.grouping
	specialty.description && (specialtyKP.Description = specialty.description)

	return (
		<KeyPropertyContainer
			title={specialty.display_name}
			keyValues={specialtyKP}
		/>
	)
}

const Specialties = ({ specialties }: { specialties: SpecialtyProps[] }) => {
	if (!specialties.length) {
		return null
	}
	const specs: JSX.Element[] = specialties.map((spec, index) => (
		<Specialty key={`item-${index}`} {...spec} />
	))
	return (
		<>
			<Heading as="h5" paddingTop="32px" paddingBottom="32px">
				Specialties
			</Heading>
			{specs}
		</>
	)
}


const ProviderInfo = (props: ProviderInfoProps) => {
	if (!props.provider) {
		return null
	}
	const { provider } = props;

	const generalKP: KeyValues = {}

	generalKP['Provider ID'] = provider.npi1

	if (GENDERS.hasOwnProperty(provider.gender)) {
		generalKP.Gender = GENDERS[provider.gender as keyof typeof GENDERS]
	}

	provider.date_of_birth && (generalKP['Date of Birth'] = provider.date_of_birth)

	if (provider.languages.length) {
		generalKP.Languages = provider.languages.join(', ')
	}

	if (provider.specialties) {
		const specialties: JSX.Element[] = []
		for (const specialty of provider.specialties) {
			specialties.push(
				<span key={specialty.taxonomy_code}>
					<SpecialtyLink
						display_name={specialty.display_name}
						taxonomy_code={specialty.taxonomy_code}
					/>
					<br />
				</span>
			)
		}
		generalKP.Specialties = specialties
	}

	let specialty = (provider.specialties.length) ? provider.specialties[0] : null
	let location = (provider.locations.length) ? provider.locations[0] : null

	return (
		<>
			<div className="provider-info">
				<ProviderNameSummary
					key="name"
					first_name={provider.first_name}
					last_name={provider.last_name}
				/>
				<SpecialtyLocationSummary
					key="location"
					specialty_name={specialty && specialty.display_name}
					city={location && location.city}
					state={location && location.state}
				/>
			</div>
			<KeyPropertyContainer
				title="General"
				keyValues={generalKP}
			/>
			<Specialties specialties={provider.specialties} />
			<Locations locations={provider.locations} />
			<Groups groups={provider.groups} />
			<StateLicenses
				licenses={provider.state_licenses}
			/>
			<DeaLicenses
				licenses={provider.dea_registrations}
			/>
			<AbmsLicenses
				licenses={provider.abms_registrations}
			/>
		</>
	)
}

export default ProviderInfo
