import { extendBaseTheme } from '@chakra-ui/react'
import vedaTheme from '@veda/veda-design-system'

// The `veda-design-system` does not use the correct font by default. It is changed
// below. In addition, the font-sizes were not to the brand specificatyions, so they
// have been updated too. Ultimately, this should go back into the `veda-design-system`
// NOTE: this completely replaces the `theme.fonts.body` (and sizes), and the
// `theme.components.Heading` that were configured in `veda-design-system`.
const extendedTheme = {
	...vedaTheme,

	fonts: {
		body: 'Rubik, sans-serif',
		fontSizes: {
			xs: '7pt',
			sm: '9pt',
			md: '12pt',
			lg: '16pt',
			xl: '21pt',
			'2xl': '28pt',
			'3xl': '38pt',
			'4xl': '51pt',
			'5xl': '64pt',
			'6xl': '90pt'
		},
	},
	components: {
		...vedaTheme.components,
		Heading: {
			...vedaTheme.components.Heading,
			defaultProps: {},
			baseStyle: {
				fontFamily: 'Rubik'
			},
			variants: {
				'with-primary': {
					color: 'primary.900',
					fontWeight: 500
				}
			}
		}
	},
	styles: {
		global: {
			h1: {
				fontSize: '5xl',
				fontWeight: 700
			},
			h2: {
				fontSize: '4xl'
			},
			h3: {
				fontSize: '3xl'
			},
			h4: {
				fontSize: '2xl'
			},
			h5: {
				fontSize: 'xl'
			},
			h6: {
				fontSize: 'lg'
			},
			b: {
				fontWeight: 500
			}
		}
	}
}

export default extendBaseTheme(extendedTheme)
