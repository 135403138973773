const {
	// NOTE: using Auth Management claim for the moment.
	REACT_APP_AUTH_MGMT_CLAIM: AUTH_MGMT_CLAIM = 'manage:clientApplications',
	REACT_APP_AUTH0_AUDIENCE: AUTH_AUDIENCE = '',
	REACT_APP_AUTH0_CLIENT_ID: AUTH_CLIENT_ID = '',
	REACT_APP_AUTH0_DOMAIN: AUTH_DOMAIN = '',
	REACT_APP_VEKTYR_DEMO_API_BASE_URL: VEKTYR_DEMO_API_BASE_URL = ''
} = process.env

const DEFAULT_ROUTE = '/search-by-npi'
const LOGIN_ROUTE = '/search-by-npi'
const AUTH_PERMISSIONS_KEY = 'https://vedadata.com/permissions'
const AUTH_REDIRECT_URI = `${window.location.origin}${DEFAULT_ROUTE}`
const AUTH_REDIRECT_LOGIN_URI = `${window.location.origin}${LOGIN_ROUTE}`

export {
	AUTH_AUDIENCE,
	AUTH_CLIENT_ID,
	AUTH_DOMAIN,
	AUTH_MGMT_CLAIM,
	AUTH_PERMISSIONS_KEY,
	AUTH_REDIRECT_LOGIN_URI,
	AUTH_REDIRECT_URI,
	DEFAULT_ROUTE,
	VEKTYR_DEMO_API_BASE_URL
}
