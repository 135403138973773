import * as React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import NavBar from 'components/NavBar'
import RequiresAuth from './RequiresAuth'
import SideBar from './SideBar'

/**
 * Layout to be used for all pages related to admin pages. Eg. Key management, Users, etc.
 */
export const LoggedInLayout = (): JSX.Element => (
	<RequiresAuth>
		<>
			<NavBar />
			<Grid templateColumns="repeat(6, 1fr)" bg="gray.50">
				{/* sidebar */}
				<GridItem
					as="aside"
					colSpan={{
						base: 6,
						lg: 2,
						xl: 1
					}}
					bg="gray.50"
					minHeight={{ lg: 'calc(100vh - 64px);' }} // minus height of banner
					p={{
						base: '20px',
						lg: '30px'
					}}
				>
					<SideBar />
				</GridItem>

				{/* main content & navbar */}
				<GridItem
					as="main"
					colSpan={{
						base: 6,
						lg: 4,
						xl: 5
					}}
					bg="white"
				>
					<Outlet />
				</GridItem>
			</Grid>
		</>
	</RequiresAuth>
)
