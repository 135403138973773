import React, { Flex, Spinner } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

export default function RequiresAuth({ children }: { children: JSX.Element }): JSX.Element {
	const {
		isAuthenticated,
		isLoading,
		logout,
		loginWithRedirect,
	} = useAuth0()
	const location = useLocation()

	const { search } = location
	const params = new URLSearchParams(search)
	if (params.get('error')) {
		// If auth0 gets an error, the query parameters will contain the `error` and `error_description`.
		// trap them here to prevent infinite login/error cycle. Note: I could not find an easy way to
		// force this to show an error page. It would be the right thing to do instead of just reloading
		// the login.
		console.error(params.get('error_description'))
		logout()
		loginWithRedirect().catch(console.error)
	}

	if (isLoading) {
		return (
			<Flex justifyContent="center" alignItems="center" height="100vh">
				<Spinner height={'80px'} width={'80px'} color="blue.500" />
			</Flex>
		)
	}

	if (isAuthenticated) {
		return children
	} else {
		logout()
		loginWithRedirect().catch(console.error)
		return <></>
	}
}
