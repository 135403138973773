import { VEKTYR_DEMO_API_BASE_URL } from 'variables'

export const getVectyrAPI = (token: string) => {
	const basePath = `${VEKTYR_DEMO_API_BASE_URL}/api/v1/providers`
	const headers = {
		accept: 'application/json',
		authorization: `Bearer ${token}`
	}

	async function makeRequest(uri: string) {
		const response = await fetch(`${basePath}${uri}`, {
			method: 'GET',
			headers
		})
		const data = await response.json()
		return {
			status: response.status,
			data
		}
	}

	return {
		getProviderByNpi(npi1: string) {
			return makeRequest(`/${npi1}`)
		}
	}
}
