import React from 'react'
import { Button, Flex, Link, List, ListIcon, ListItem } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Search2Icon, ChevronLeftIcon } from '@chakra-ui/icons'
import { AUTH_REDIRECT_LOGIN_URI } from 'variables'
import './SideBar.css'

export default function SideBar(): JSX.Element {
	const { logout } = useAuth0()

	return (
		<Flex id="side-bar" direction={'column'} height={'100%'}>
			<List fontSize="1.2em" spacing={4} color="#0D0038">
				<ListItem>
					<Link as={NavLink} to="/search-by-npi">
						<ListIcon as={Search2Icon} />
						NPI Search
					</Link>
				</ListItem>
			</List>

			<Button
				leftIcon={<ChevronLeftIcon />}
				alignSelf={'center'}
				size="md"
				variant="ghost"
				onClick={() => logout({ logoutParams: { returnTo: AUTH_REDIRECT_LOGIN_URI } })}
				height={'50px'}
				borderTop={'1px solid #E2E8F0'}
				width={'100%'}
				marginTop={'auto'}
			>
				Logout
			</Button>
		</Flex>
	)
}
