import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { ChakraBaseProvider, extendBaseTheme } from '@chakra-ui/react'
import {
	AUTH_AUDIENCE,
	AUTH_CLIENT_ID,
	AUTH_DOMAIN,
	AUTH_REDIRECT_URI
} from 'variables'
import reportWebVitals from './reportWebVitals'
import { router } from './routes'
import extendedTheme from 'theme'
import './index.css'

const theme = extendBaseTheme(extendedTheme)

const container = document.getElementById('root')
if (!container) {
	throw new Error('Failed to find the root element')
}

const getMainContainer = () => {
	if (AUTH_DOMAIN
		&& AUTH_CLIENT_ID
		&& AUTH_REDIRECT_URI
		&& AUTH_AUDIENCE) {
		return (
			<Auth0Provider
				domain={AUTH_DOMAIN}
				clientId={AUTH_CLIENT_ID}
				authorizationParams={{
					scope: 'openid profile email read:providers',
					redirect_uri: AUTH_REDIRECT_URI,
					audience: AUTH_AUDIENCE
				}}>
				<ChakraBaseProvider theme={theme}>
					<RouterProvider router={router} />
				</ChakraBaseProvider>
			</Auth0Provider>
		)
	}
	return (
		<div>Configuration error.</div>
	)
}

ReactDOM.createRoot(container).render(getMainContainer());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
