import {
	TableContainer,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td
} from '@chakra-ui/react'
import './KeyPropertyContainer.css'

export type KeyValues = { [key: string]: any }

type KeyPropertyContainerProps = {
	title: string,
	keyValues: KeyValues
}

export const KeyPropertyContainer = (props: KeyPropertyContainerProps) => {
	const rows: JSX.Element[] = []
	for (const key in props.keyValues) {
		const value = props.keyValues[key];
		rows.push(
			<Tr key={key}>
				<Td width="50%"><b>{key}</b></Td>
				<Td>{value}</Td>
			</Tr>
		)
	}

	return (
		<TableContainer className="key-properties">
			<Table>
				<Thead>
					<Tr>
						<Th colSpan={2} backgroundColor={""}>{props.title}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{rows}
				</Tbody>
			</Table>
		</TableContainer>
	)
}
