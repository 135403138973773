import React, { useEffect, useRef, useState } from 'react'
import {
	Box,
	Flex,
	Button,
	FormControl,
	FormErrorMessage,
	Input,
	InputGroup,
	InputRightAddon,
	Spinner
} from '@chakra-ui/react'
import vedaLogo from '../../assets/veda-logo.svg'
import { getVectyrAPI } from 'clients/getVectyrAPI'
import ProviderInfo from './ProviderInfo'
import './SearchByNpi.css'
import { useAuth0 } from '@auth0/auth0-react'
import luhn from "fast-luhn"

const NPI_VALUE_LENGTH = 10

export type SearchFunction = (npi: string) => void

function is_valid_npi1(npi1: string): boolean {
	return npi1.match(/^[12]\d{9}$/) !== null && luhn(`80840${npi1}`);
}

export default function SearchByNpi() {
	const [ errorMessage, setError ] = useState('')
	const [ npiSearchValue, setNpiSearch ] = useState('')
	const [ provider, setProvider ] = useState(null)
	const [ isLoading, setLoading ] = useState(false)
	const [ token, setToken ] = useState('')
	const searchButton = useRef<any>(null)

	const {
		getAccessTokenSilently
	} = useAuth0()

	useEffect(() => {
		const getUserToken = async () => {
			try {
				const accessToken = await getAccessTokenSilently();
				setToken(accessToken)
			} catch (ex) {
				console.error(ex)
				setError('Failed to obtain access token')
			}
		}
		getUserToken()
	})

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target
		if (value && !value.match(/^\d+$/)) {
			setError('NPI can only contain digits. Please correct the NPI and try again.')
		} else if (!is_valid_npi1(value)) {
			setError('Invalid NPI1')
		} else {
			setError('')
		}
		setNpiSearch(value)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		if (event.key === 'Enter') {
			// trigger the search button click (extra validation)
			searchButton.current?.click()
		}
	}

	const handleSearchClick = async () => {
		if (npiSearchValue.length < NPI_VALUE_LENGTH) {
			setError('The NPI you searched for did not contain enough characters. Please try again.')
			return
		}
		if (isLoading) {
			return
		}
		setError('')
		setLoading(true)

		const vektyrClient = getVectyrAPI(token)
		try {
			const response = await vektyrClient.getProviderByNpi(npiSearchValue)
			if (response.status === 200) {
				setProvider(response.data)
			} else if (response.data) {
				console.error(response)
				if (response.data.errors) {
					// our API errors
					setError(`${response.data.errors[0]}`)
				} else if (response.data.message) {
					// other errors, e.g. gateway
					setError(`${response.data.message}`)
				} else {
					// unknown
					setError(`${response.data}`)
				}
			}
			setLoading(false)
		} catch (ex) {
			const message = (ex instanceof Error) ? ex.message : `error: ${ex}`
			setError(message)
			setLoading(false)
		}
	}

	return (
		<Box padding="32px">
			<Flex justifyContent="middle" id="npi-search-container">
				<img src={vedaLogo} className="veda-logo" alt="logo" />
				<FormControl width={'33vw'} id="npi-search-form" isRequired isInvalid={errorMessage !== ''}>
					<InputGroup size="md">
						<Input
							name="npi"
							size="lg"
							type="text"
							isDisabled={isLoading}
							value={npiSearchValue}
							placeholder="Search an NPI number to get started"
							onKeyDown={handleKeyDown}
							onChange={handleInputChange}
						/>
						<InputRightAddon height="48px">
							<Button
								ref={searchButton}
								isDisabled={isLoading}
								isLoading={isLoading}
								loadingText="Searching"
								id="search-button"
								className="search"
								size="sm"
								onClick={handleSearchClick}
							>
								Search
							</Button>
						</InputRightAddon>
					</InputGroup>
					<FormErrorMessage>{errorMessage}</FormErrorMessage>
				</FormControl>
			</Flex>
			{isLoading && (
				<Flex justifyContent="center" alignItems="center" height="50vh">
					<Spinner height={'80px'} width={'80px'} color="blue.500" />
				</Flex>
			)}
			{!isLoading && !errorMessage && (
				<ProviderInfo provider={provider} />
			)}
		</Box>
	)
}
